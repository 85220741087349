import React from "react"
import * as styles from "../styles/card.module.scss"

export const Card = ({ children }) => (
  <div className={styles.card}>{children}</div>
)

export const CardTitle = ({ children }) => (
  <h2 className={styles.title}> {children} </h2>
)

export const CardContent = ({
  dangerouslySetInnerHTML,
  cardLinkText,
  cardLinkHref,
}) => (
  <div className={styles.content}>
    <span dangerouslySetInnerHTML={dangerouslySetInnerHTML}></span>
    {cardLinkText && cardLinkHref ? (
      <a href={cardLinkHref} class={styles.cardLink}>
        {cardLinkText}
      </a>
    ) : null}
  </div>
)

export const CardImage = ({ fluid, alt }) => (
  <div className={styles.imageWrapper}>
    <img
      className={styles.image}
      src={fluid.src}
      srcSet={fluid.srcSet}
      sizes={fluid.sizes}
      alt={alt}
    />
  </div>
)
