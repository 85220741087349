import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import LandingPageCards from '../components/landingPageCards'
import Map from '../components/map'

const IndexPage = () => (

  <Layout>
    <SEO title='Home' keywords={[`gatsby`, `application`, `react`]} />
    <Hero />
    <LandingPageCards />
    <Map />
  </Layout>
)


export default IndexPage