import React from "react"
import * as styles from "../styles/hero.module.scss"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const Hero = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "springen.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logo: file(relativePath: { eq: "logo/logo_etfk_logo_schwarz.svg" }) {
          publicURL
        }
      }
    `}
    render={(data) => (
      <div className={styles.container}>
        <Img
          fluid={data.heroImage.childImageSharp.fluid}
          objectFit="cover"
          className={styles.heroImage}
          alt="Texturen"
          imgStyle={
            {
              //filter: 'blur(20px)'
            }
          }
          objectPosition="50% 0"
        />
        {children}
        <div className={styles.solidOverlay} />
        <img
          className={styles.logoOverlay}
          src={data.logo.publicURL}
          alt="logo"
        />
      </div>
    )}
  />
)

export default Hero
