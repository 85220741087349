import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Card, CardTitle, CardContent, CardImage } from "./card"

const CardForNode = ({ html, frontmatter }) => (
  <Card>
    <CardTitle>{frontmatter.title}</CardTitle>
    <CardImage fluid={frontmatter.image.childImageSharp.fluid} />
    <CardContent
      dangerouslySetInnerHTML={{ __html: html }}
      cardLinkText={frontmatter.cardLinkText}
      cardLinkHref={frontmatter.cardLinkHref}
    ></CardContent>
  </Card>
)

const LandingPageCards = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          sort: { order: ASC, fields: [fileAbsolutePath] }
          filter: { frontmatter: { posttype: { eq: "landing-page-card" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                title
                cardLinkHref
                cardLinkText
                image {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        {data.allMarkdownRemark.edges
          .map((node) => node.node)
          .map((node) => (
            <CardForNode {...node} />
          ))}
      </>
    )}
  />
)

export default LandingPageCards
